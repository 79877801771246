import https from "./https"
import store from "@/store"

const invoice = {
  getInvoiceCarriers(params) {
    const organization = store.state.general.organization
    return https.get('admin/organizations/' + organization.id + '/invoice-carriers', {params})
  },
  getValidStatusOptions() {
    const organization = store.state.general.organization
    return https.get('admin/organizations/' + organization.id + '/invoice-carriers/valid-status-options')
  },
}

export default invoice
